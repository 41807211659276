// Generated by Framer (c747e62)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["NqOxhseXG", "SHs2Z386d"];

const serializationHash = "framer-Qvsxj"

const variantClassNames = {NqOxhseXG: "framer-v-1sbz2xs", SHs2Z386d: "framer-v-1t35jav"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "SHs2Z386d", Open: "NqOxhseXG"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, M3OiwFKXY: click ?? props.M3OiwFKXY, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NqOxhseXG"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, M3OiwFKXY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NqOxhseXG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapza1c6n = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (M3OiwFKXY) {const res = await M3OiwFKXY(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1sbz2xs", className, classNames)} data-framer-name={"Open"} data-highlight layoutDependency={layoutDependency} layoutId={"NqOxhseXG"} onTap={onTapza1c6n} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({SHs2Z386d: {"data-framer-name": "Closed"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-13yhlp5-container"} layoutDependency={layoutDependency} layoutId={"W2w6mIEAc-container"}><Feather color={"var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))"} height={"100%"} iconSearch={"Home"} iconSelection={"menu"} id={"W2w6mIEAc"} layoutId={"W2w6mIEAc"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({SHs2Z386d: {iconSelection: "x"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Qvsxj.framer-r5cfo6, .framer-Qvsxj .framer-r5cfo6 { display: block; }", ".framer-Qvsxj.framer-1sbz2xs { cursor: pointer; height: 32px; overflow: visible; position: relative; width: 32px; }", ".framer-Qvsxj .framer-13yhlp5-container { bottom: 0px; flex: none; left: calc(48.484848484848506% - 32px / 2); position: absolute; top: 0px; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SHs2Z386d":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"M3OiwFKXY":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSquIPw5dI: React.ComponentType<Props> = withCSS(Component, css, "framer-Qvsxj") as typeof Component;
export default FramerSquIPw5dI;

FramerSquIPw5dI.displayName = "Navigation/Menu icon";

FramerSquIPw5dI.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerSquIPw5dI, {variant: {options: ["NqOxhseXG", "SHs2Z386d"], optionTitles: ["Open", "Closed"], title: "Variant", type: ControlType.Enum}, M3OiwFKXY: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerSquIPw5dI, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})